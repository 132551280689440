// const URL = "https://graze.dagnum.com/images/";
const URL = "images/media/";

export const photos = [
  {
    src: URL + "IMG_0606.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_0609.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_0616.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_0617.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_0619.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_0620.jpg",
    width: 0.563,
    height: 1,
  },

  {
    src: URL + "IMG_1111.jpg",
    width: 1.8050541516245486,
    height: 1,
  },

  {
    src: URL + "IMG_1113.jpg",
    width: 0.563,
    height: 1,
  },

  {
    src: URL + "IMG_1282.jpg",
    width: 0.462,
    height: 1,
  },

  {
    src: URL + "IMG_1422.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_1426.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_1434.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_1535.jpg",
    width: 1.6,
    height: 1,
  },

  {
    src: URL + "IMG_1964.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_1965.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_1973.jpg",
    width: 0.563,
    height: 1,
  },

  {
    src: URL + "IMG_1986.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_1988.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_1991.jpg",
    width: 0.563,
    height: 1,
  },

  {
    src: URL + "IMG_2006.jpg",
    width: 0.462,
    height: 1,
  },

  {
    src: URL + "IMG_2165.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_2173.jpg",
    width: 0.563,
    height: 1,
  },

  {
    src: URL + "IMG_2863.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_2864.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_2873.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_3074.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_3075.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_3076.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_3077.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_3195.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_3335.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_3686.jpg",
    width: 0.562,
    height: 1,
  },

  {
    src: URL + "IMG_3894.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_3900.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_3902.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_3906.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_3913.jpg",
    width: 0.462,
    height: 1,
  },

  {
    src: URL + "IMG_3914.jpg",
    width: 0.462,
    height: 1,
  },

  {
    src: URL + "IMG_4707.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_4708.jpg",
    width: 0.563,
    height: 1,
  },

  {
    src: URL + "IMG_4710.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_4711.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_4712.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_4713.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_4714.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_4715.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_4717.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_4721.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_4739.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_4741.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_5762.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_5763.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_5764.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_6462.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_6618.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_7698.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_7703.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_7705.jpg",
    width: 0.563,
    height: 1,
  },

  {
    src: URL + "IMG_7828.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_7835.jpg",
    width: 0.563,
    height: 1,
  },

  {
    src: URL + "IMG_8427.jpg",
    width: 0.563,
    height: 1,
  },

  {
    src: URL + "IMG_8429.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_8431.jpg",
    width: 0.75,
    height: 1,
  },

  {
    src: URL + "IMG_8432.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_8434.jpg",
    width: 1.3333333333333333,
    height: 1,
  },

  {
    src: URL + "IMG_8436.jpg",
    width: 1.3333333333333333,
    height: 1,
  },
];

// function add(name) {
//   const img = new Image();
//   var srcImg = URL + name;
//   img.src = srcImg;
//   var ratio = img.width / img.height;
//   console.log(ratio);
//   photos = photos.concat([
//     {
//       src: srcImg,
//       width: ratio,
//       height: 1,
//     },
//   ]);
// }

// // add("IMG_0606.jpg");

// var name = [
//   "IMG_0606.jpg",
//   "IMG_0609.jpg",
//   "IMG_0616.jpg",
//   "IMG_0617.jpg",
//   "IMG_0619.jpg",
//   "IMG_0620.jpg",
//   "IMG_1111.jpg",
//   "IMG_1113.jpg",
//   "IMG_1282.jpg",
//   "IMG_1422.jpg",
//   "IMG_1426.jpg",
//   "IMG_1434.jpg",
//   "IMG_1535.jpg",
//   "IMG_1964.jpg",
//   "IMG_1965.jpg",
//   "IMG_1973.jpg",
//   "IMG_1986.jpg",
//   "IMG_1988.jpg",
//   "IMG_1991.jpg",
//   "IMG_2006.jpg",
//   "IMG_2165.jpg",
//   "IMG_2173.jpg",
//   "IMG_2863.jpg",
//   "IMG_2864.jpg",
//   "IMG_2873.jpg",
//   "IMG_3074.jpg",
//   "IMG_3075.jpg",
//   "IMG_3076.jpg",
//   "IMG_3077.jpg",
//   "IMG_3195.jpg",
//   "IMG_3335.jpg",
//   "IMG_3686.jpg",
//   "IMG_3894.jpg",
//   "IMG_3900.jpg",
//   "IMG_3902.jpg",
//   "IMG_3906.jpg",
//   "IMG_3913.jpg",
//   "IMG_3914.jpg",
//   "IMG_4707.jpg",
//   "IMG_4708.jpg",
//   "IMG_4710.jpg",
//   "IMG_4711.jpg",
//   "IMG_4712.jpg",
//   "IMG_4713.jpg",
//   "IMG_4714.jpg",
//   "IMG_4715.jpg",
//   "IMG_4717.jpg",
//   "IMG_4721.jpg",
//   "IMG_4739.jpg",
//   "IMG_4741.jpg",
//   "IMG_5762.jpg",
//   "IMG_5763.jpg",
//   "IMG_5764.jpg",
//   "IMG_6462.jpg",
//   "IMG_6618.jpg",
//   "IMG_7698.jpg",
//   "IMG_7703.jpg",
//   "IMG_7705.jpg",
//   "IMG_7828.jpg",
//   "IMG_7835.jpg",
//   "IMG_8427.jpg",
//   "IMG_8429.jpg",
//   "IMG_8431.jpg",
//   "IMG_8432.jpg",
//   "IMG_8434.jpg",
//   "IMG_8436.jpg",
// ];

// name.forEach(add);

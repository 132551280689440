import React, { Component } from "react";

export default class Navbar extends Component {
  render() {
    return (
      <>
        <nav class="navbar bg-light">
          <div class="container-fluid">
            <a class="navbar-brand" href="/">
              <img
                src="/images/logo.png"
                alt=""
                width="60%"
                height="80%"
                className="d-inline-block align-text-top "
              />
            </a>
            <a href="https://poplme.co/hash/BSO3psdm/2/a">
              <button type="button" className="btn btn-dark">
                Contact
              </button>
            </a>
          </div>
        </nav>
      </>
    );
  }
}
